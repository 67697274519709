import {CustomLink} from "./CustomLink";

const ServiceBlock = ({title, imgHref, link}) => {
  return (
    <div className="service-block">
      <div className="service-block__img">
        <img src={imgHref} alt="service-icon"/>
      </div>
      <h3>{title}</h3>
      <div className="service-block__link">
        <CustomLink to={link}/>
      </div>
    </div>
  )
}

export {ServiceBlock}
