const InfoBlock = ({theme, imgHref, title, info, type}) => {
  return (
    <div className={`info-block ${theme}`}>
      <div className='info-block__img'>
        <img src={imgHref} alt="info-icon"/>
      </div>
      <div className='info-block__text'>
        <h3>{title}</h3>
        {!type && (
          <p>{info}</p>
        )}
        {type === "phone" && (
          <a href={`tel: ${info}`}>{info}</a>
        )}
        {type === "email" && (
          <a href={`mailto: ${info}`}>{info}</a>
        )}
      </div>
    </div>
  )
}

export {InfoBlock}
