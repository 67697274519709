export const activateScrollPage = (height, activeLink, setActiveLink) => {
  const observerMargin = height ? Math.floor(height / 2) : 0;

  const observerConfig = {
    rootMargin: `-${height % 2 === 0 ? observerMargin - 1 : observerMargin}px 0px -${observerMargin}px 0px`,
  };

  const handleIntersection = function(entries) {
    entries.forEach((entry) => {
      if (entry.target.id !== activeLink && entry.isIntersecting) {
        setActiveLink(entry.target.id);
      }
    });
  };

  const observer = new IntersectionObserver(handleIntersection, observerConfig);

  document.querySelectorAll('section')
    .forEach((section) => {observer.observe(section)});

  return () => observer.disconnect();
}
