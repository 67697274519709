import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

const TermsOfUsePage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('termsOfUsePage', {returnObjects: true});

  const [messages, setMessages] = useState(defaultMessage);

  useEffect(() => {
    setMessages(defaultMessage);
  }, [i18n.language, t]);

  const links = [
    {href: "https://www.freepik.com/free-photo/business-executives-with-hand-stacked_1005993.htm#page=3&query=team&position=27&from_view=search&track=sph", author: "Image by peoplecreations", info: "on Freepik"},

    {href: "https://www.freepik.com/free-photo/woman-man-shake-hands-partners-made-deal-created-with-generative-ai-technology_40871262.htm#query=deal&position=8&from_view=search&track=sph", author: "Image by atlascompany", info: "on Freepik"},
    {href: "https://www.freepik.com/free-photo/portrait-pretty-young-woman-celebrating-success-laptop-isolated-white-background_26725542.htm#query=freelanc&position=35&from_view=search&track=sph", author: "Image by diana.grytsku", info: "on Freepik"},
    {href: "https://www.freepik.com/free-photo/portrait-smiling-man-sitting-cafe-bar-with-his-laptop-computer_11138110.htm#query=freelanc&position=41&from_view=search&track=sph", author: "Image by aleksandarlittlewolf", info: "on Freepik"},
    {href: "https://www.freepik.com/free-photo/pleased-female-secretary-trendy-glasses-posing-office-after-meeting-with-colleagues-indoor-portrait-stylish-businesswoman-with-asian-african-workers_10483958.htm#page=5&query=employees&position=1&from_view=keyword&track=sph", author: "Image by lookstudio", info: "on Freepik"},
    {href: "https://www.freepik.com/free-photo/portrait-cnc-machine-machine-operator-standing-factory-looking-camera_25856135.htm#query=cnc&position=12&from_view=author", author: "Image by Drazen Zigic", info: "on Freepik"},
    {href: "https://www.freepik.com/free-vector/white-background-with-wavy-lines-copy-space_12067561.htm#query=abstract%20lines&position=0&from_view=keyword&track=ais", author: "Image from Freepik", info: "on Freepik"},
  ]

  return (
    <div className="terms-and-policy__page page">
      <section>
        <div className="section-wrapper">
          <h2>{messages.title}</h2>
        </div>
      </section>
      {messages.blocks.map((el, index) => (
        <section key={index}>
          <div className="section-wrapper">
            {el.title ? <h3>{el.title}</h3> : null}

            {el.address && (
              <div>
                <p>{el.address}</p>
              </div>
            )}

            {el.phone && el.email && (
              <div>
                <p>{el.phone}: <a href="tel: +4915732241455">+49 157 32241455</a></p>
                <p>{el.email}: <a href="mailto: fs@focus-strategy.de">fs@focus-strategy.de</a></p>
              </div>
            )}

            {el.about && el.about.map((el, index) => (
              <p key={index}>{el}</p>
            ))}

            <p>{el.info}</p>

            {el.options && (
              <ul>
                {el.options.map((el, index) => (
                  <li key={index}>{el}</li>
                ))}
              </ul>
            )}

            {el.info2 ? <p>{el.info2}</p> : null}
          </div>
        </section>
      ))}

      <section>
        <div className="section-wrapper">
          <h3>{messages.links.title}</h3>
          {links.map((el, index) => (
            <div>
              <a href={el.href} rel="noopener noreferrer" target="_blank">{el.author}</a> <span> - {el.info}</span>
            </div>
          ))}
          <p>{messages.links.info}</p>
        </div>
      </section>
    </div>
  )
}

export {TermsOfUsePage}
