import {useEffect, useContext} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

import {Context} from "../common/scripts/context";

const ConfirmationPage = ({pageType}) => {
  const {t} = useTranslation();
  const {setFooterTheme} = useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFooterTheme('light');
  }, [setFooterTheme]);

  return (
    <div className="thank-you__page page">
      {pageType === "services" && (
        <section className="thank-you__page-main">
          <h2>{t("confirmationPage.contactUs.title")}</h2>
          <p>{t("confirmationPage.contactUs.description")}</p>
          <NavLink className='nav-button' to='/'>
            {t("confirmationPage.button")}
          </NavLink>
        </section>
      )}
      {pageType === "demo-request" && (
        <section className="thank-you__page-main">
          <h2>{t("confirmationPage.demoRequest.title")}</h2>
          <p>{t("confirmationPage.demoRequest.description")}</p>
          <NavLink className='nav-button' to='/'>
            {t("confirmationPage.button")}
          </NavLink>
        </section>
      )}
      {pageType === "register-for-training" && (
        <section className="thank-you__page-main">
          <h2>{t("confirmationPage.registerForTraining.title")}</h2>
          <p>{t("confirmationPage.registerForTraining.description")}</p>
          <NavLink className='nav-button' to='/'>
            {t("confirmationPage.button")}
          </NavLink>
        </section>
      )}
    </div>
  )
}

export {ConfirmationPage}
