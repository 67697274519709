import {useParams} from "react-router";
import {useState, useEffect, useContext} from "react";
import {useTranslation} from "react-i18next";

import {Context} from "../common/scripts/context";
import {useWindowSize} from "../common/hooks/useWindowSize";
import {activateScrollPage} from "../common/scripts/activateScrollPage";
import {Paragraphs} from "../components/Paragraphs";
import {PageNav} from "../components/PageNav";

import firstCareersImage from "../common/images/sn-project1.png";

const CareersOpportunityPage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('careersOpportunityPage.body', {returnObjects: true});
  const defaultLinksMessage = t('careersOpportunityPage.links', {returnObjects: true});

  const {setFooterTheme} = useContext(Context);
  const [currentOpportunity, setCurrentOpportunity] = useState();
  const [activeLink, setActiveLink] = useState();
  const [projects, setProjects] = useState(defaultMessage);
  const [links, setLinks] = useState(defaultLinksMessage);
  const {height} = useWindowSize();
  const {linkTitle} = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProjects(defaultMessage);
    setLinks(defaultLinksMessage);
  }, [i18n.language, t]);

  useEffect(() => {
    setCurrentOpportunity(projects?.find((el) => el.linkTitle === linkTitle));
  }, [projects, linkTitle]);

  useEffect(() => {
    setFooterTheme('light align-left');
  }, [setFooterTheme]);

  useEffect(() => {
    activateScrollPage(height, activeLink, setActiveLink);
  }, [activeLink, setActiveLink, height]);

  const verticalMenuLinkArray = [
    {href: "#main", title: links?.[0]},
    {href: "#benefits", title: links?.[1]},
    {href: "#resources", title: links?.[2]},
  ];

  const imagesArray = [
    {linkTitle: "opportunity-1", imgHref: firstCareersImage},
    {linkTitle: "opportunity-2", imgHref: firstCareersImage},
    {linkTitle: "opportunity-3", imgHref: firstCareersImage},
    {linkTitle: "opportunity-4", imgHref: firstCareersImage},
  ];

  return (
    <div className="project-page page">
      <div className="project-page__blocks">
        <div className="project-page__info-block">
          <section className='project-page__main' id="main">
            <h2>{currentOpportunity?.main.title}</h2>
            <p>{currentOpportunity?.main.description}</p>
            <img
              src={imagesArray.find((el) => el.linkTitle === linkTitle).imgHref}
              alt="Project screen"/>
          </section>

          <section className='project-page__benefits' id="benefits">
            <Paragraphs paragraphsList={currentOpportunity?.benefits} theme="dark"/>
          </section>

          <section className='project-page__resources' id="resources">
            <div className='list-block'>
              <h3>{currentOpportunity?.resources.title}</h3>
              <p>{currentOpportunity?.resources.description}</p>

              {currentOpportunity?.resources.options.map((el, index) => (
                <div className="list-block__item" key={index}>
                  <h3>{el.title}</h3>
                  <p>{el.description}</p>
                </div>
              ))}
            </div>
          </section>
        </div>

        <div className="project-page__menu-block">
          <PageNav linkArray={verticalMenuLinkArray} activeLink={activeLink}/>
        </div>
      </div>
    </div>
  )
}

export {CareersOpportunityPage}
