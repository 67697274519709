import {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {Context} from "../common/scripts/context";
import {ServiceBlock} from "../components/ServiceBlock";

import codeBracesLightImage from "../common/images/code-braces-light.svg";
import chartLineLightImage from "../common/images/chart-line-light.svg";
import schoolOutlineLightImage from "../common/images/school-outline-light.svg";

const ServicesPage = () => {
  const {t} = useTranslation();
  const {setFooterTheme} = useContext(Context);

  const servicesParams = [
    {
      imgHref: codeBracesLightImage,
      link: "/services/servicenow",
      title: t('servicesPage.blockTitle1')
    },
    {
      imgHref: chartLineLightImage,
      link: "/services/data-analytics",
      title: t('servicesPage.blockTitle2')
    },
    {
      imgHref: schoolOutlineLightImage,
      link: "/services/servicenow-training",
      title: t('servicesPage.blockTitle3')
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFooterTheme('light');
  }, [setFooterTheme]);

  return (
    <div className='services-page page'>
      <section>
        <div className="section-wrapper">
          <h2>{t('servicesPage.title')}</h2>
          <p>{t('servicesPage.description')}</p>

          <div className='services-page__block'>
            {servicesParams.map((el, index) => (
              <ServiceBlock link={el.link} imgHref={el.imgHref} title={el.title} key={index}/>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export {ServicesPage}
