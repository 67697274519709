import {useEffect, useState, useCallback} from "react";
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router";
import {useTranslation} from "react-i18next";
import {NavDropdown, Dropdown, DropdownButton} from 'react-bootstrap';
import {getDefaultLanguage} from "../common/scripts/getDefaultLanguage";

import logoImage from '../common/images/logo.svg';

const Header = () => {
  const {pathname} = useLocation();
  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState('en');
  const [isActiveClass, setIsActiveClass] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  useEffect(() => {
    setLanguage(localStorage.getItem("language") ?? getDefaultLanguage());
  }, [])

  useEffect(() => {
    setIsActiveClass(pathname.includes("services"));
  }, [pathname])

  const handleChangeLanguage = (eventKey) => {
    setLanguage(eventKey);
    i18n.changeLanguage(eventKey);
    localStorage.setItem("language", eventKey);
  }

  const onToggleServicesDropdown = useCallback(() => {
      setIsOpenDropdown(!isOpenDropdown);
    }, [isOpenDropdown, setIsOpenDropdown],
  );

  const onToggleMenu = useCallback((e) => {
    if (!e.target.classList.contains("dropdown-toggle")) {
      setIsOpenMenu(!isOpenMenu);
    }}, [isOpenMenu, setIsOpenMenu],
  );

  return (
    <header>
      <div className="section-wrapper">
        <NavLink className="logo-link" to="/">
          <img src={logoImage} alt='logo'/>
        </NavLink>

        <div className="nav-buttons">
          <NavLink
            className="nav-buttons__contacts nav-button"
            to="/contact-us"
          >
            {t('header.contactButton')}
          </NavLink>
          <button className="nav-buttons__burger" onClick={onToggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path d="M4 24H28V21.3333H4V24ZM4 17.3333H28V14.6667H4V17.3333ZM4 8V10.6667H28V8H4Z" fill="#366092"/>
            </svg>
          </button>
        </div>

        <nav className={isOpenMenu ? "open-mobile-nav" : ""} onClick={isOpenMenu ? onToggleMenu : null}>
          <div className="close-burger">
            <button className="close-burger__button" onClick={onToggleMenu}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M25.3332 8.54663L23.4532 6.66663L15.9998 14.12L8.5465 6.66663L6.6665 8.54663L14.1198 16L6.6665 23.4533L8.5465 25.3333L15.9998 17.88L23.4532 25.3333L25.3332 23.4533L17.8798 16L25.3332 8.54663Z" fill="#366092"/>
              </svg>
            </button>
          </div>
          <NavLink to='/'>{t('header.links.home')}</NavLink>
          <NavDropdown
            className={`services-dropdown ${isActiveClass ? "active" : ''}`}
            title={t('header.links.services')}
            show={isOpenDropdown}
            onToggle={(isOpen) => {
              setIsOpenDropdown(isOpen);
            }}
          >
            <NavLink to='/services/servicenow' onClick={onToggleServicesDropdown}>
              {t('header.links.servicesPages.serviceNow')}
            </NavLink>
            <NavLink to='/services/data-analytics' onClick={onToggleServicesDropdown}>
              {t('header.links.servicesPages.businessIntelligence')}
            </NavLink>
            <NavLink to='/services/servicenow-training' onClick={onToggleServicesDropdown}>
              {t('header.links.servicesPages.serviceNowTraining')}
            </NavLink>
          </NavDropdown>
          <NavLink to='/about'>{t('header.links.about')}</NavLink>
          <NavLink to='/contacts'>{t('header.links.contacts')}</NavLink>
          <NavLink to='/careers'>{t('header.links.careers')}</NavLink>

          <div className='language-dropdown-block'>
            <DropdownButton
              className='dropdown-button'
              title={language.toUpperCase()}
              onSelect={handleChangeLanguage}
            >
              <Dropdown.Item eventKey="en" active={language === 'en'}>
                {t('header.links.languages.english')}
              </Dropdown.Item>
              <Dropdown.Item eventKey="de" active={language === 'de'}>
                {t('header.links.languages.german')}
              </Dropdown.Item>
              {/* <Dropdown.Item eventKey="ru" active={language === 'ru'}>
              {t('header.links.languages.russian')}
            </Dropdown.Item> */}
            </DropdownButton>
          </div>

          <div className='language-dropdown-block mobile'>
            <DropdownButton
              className='dropdown-button'
              title={`${t('header.links.mobileLanguages.title')} (${language.toUpperCase()})`}
              onSelect={handleChangeLanguage}
            >
              <Dropdown.Item eventKey="en" active={language === 'en'}>
                {t('header.links.mobileLanguages.english')}
              </Dropdown.Item>
              <Dropdown.Item eventKey="de" active={language === 'de'}>
                {t('header.links.mobileLanguages.german')}
              </Dropdown.Item>
              {/* <Dropdown.Item eventKey="ru" active={language === 'ru'}>
              {t('header.links.mobileLanguages.russian')}
            </Dropdown.Item> */}
            </DropdownButton>
          </div>

          {/*<NavLink to='/1'>Login</NavLink>*/}
        </nav>
      </div>
    </header>
  )
}

export {Header}
