import {useEffect} from "react";
import {useTranslation} from "react-i18next";

import {ContactForm} from "../components/ContactForm";

const PageWithForm = ({pageType, formType}) => {
  const {t} = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-with-form page">
      {pageType === "contact-us" && (
        <section>
          <div className="section-wrapper">
            <h2>{t("contactUsPage.title")}</h2>
            {formType === 'service-now' && (
              <p>{t("contactUsPage.info1")}</p>
            )}
            {formType === 'business-intelligence' && (
              <p>{t("contactUsPage.info2")}</p>
            )}
            <ContactForm theme='light' pageType='contact-us' formType={formType}/>
          </div>
        </section>
      )}
      {pageType === "demo-request" && (
        <section>
          <div className="section-wrapper">
            <h2>{t("demoRequestPage.title")}</h2>
            <p>{t("demoRequestPage.info")}</p>
            <ContactForm theme='light' pageType='demo-request'/>
          </div>
        </section>
      )}
      {pageType === "register-for-training" && (
        <section>
          <div className="section-wrapper">
            <h2>{t("registerForTrainingPage.title")}</h2>
            <p>{t("registerForTrainingPage.info")}</p>
            <ContactForm theme="light" pageType="register-for-training"/>
          </div>
        </section>
      )}
      {pageType === "apply-now" && (
        <section>
          <div className="section-wrapper">
            <h2>{t("applyNowPage.title")}</h2>
            <p>{t("applyNowPage.info")}</p>
            <ContactForm theme="light" pageType="apply-now"/>
          </div>
        </section>
      )}
    </div>
  )
}

export {PageWithForm}
