import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

const PrivacyPolicyPage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('privacyPolicyPage', {returnObjects: true});

  const [messages, setMessages] = useState(defaultMessage);

  useEffect(() => {
    setMessages(defaultMessage);
  }, [i18n.language, t]);

  return (
    <div className="terms-and-policy__page page">
      <section>
        <div className="section-wrapper">
          <h2>{messages.title}</h2>
        </div>  
      </section>
      {messages.blocks.map((el, index) => (
        <section key={index}>
          <div className="section-wrapper">
            {el.title ? <h3>{el.title}</h3> : null}

            {el.address && (
              <div>
                <p>{el.address}</p>
              </div>
            )}

            {el.phone && el.email && (
              <div>
                <p>{el.phone}: <a href="tel: +4915732241455">+49 157 32241455</a></p>
                <p>{el.email}: <a href="mailto: fs@focus-strategy.de">fs@focus-strategy.de</a></p>
              </div>
            )}

            {el.about && el.about.map((el, index) => (
              <p key={index}>{el}</p>
            ))}

            <p>{el.info}</p>

            {el.options && (
              <ul>
                {el.options.map((el, index) => (
                  <li key={index}>{el}</li>
                ))}
              </ul>
            )}

            {el.info2 ? <p>{el.info2}</p> : null}
          </div>
        </section>
      ))}
    </div>
  );
}

export {PrivacyPolicyPage}
