import {useState, useEffect} from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";
import Dropzone from "react-dropzone";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {useForm} from "react-hook-form";
import axios from "axios";

import uploadImage from '../common/images/upload.svg'

const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
const PHONE_REGEXP = /^[\+]?[0-9]{1,3}[0-9]{4,14}$/i;

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const ContactForm = ({theme, pageType, formType}) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const defaultMessage = t('contactForm', {returnObjects: true});
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [messages, setMessages] = useState(defaultMessage);
  const [dropFile, setDropFile] = useState('');
  const [base64DropFile, setBase64DropFile] = useState('');
  const [isDroppedFile, setIsDroppedFile] = useState(false);
  const [isSizeFileError, setIsSizeFileError] = useState(false);
  const [isFormatFileError, setIsFormatFileError] = useState(false);
  const [questionType, setQuestionType] = useState(messages.questionTypeDropdown[0]);

  const handleChangeType = (eventKey) => {
    setQuestionType(eventKey);
  }

  useEffect(() => {
    setMessages(defaultMessage);
  }, [i18n.language, t]);

  useEffect(() => {
    const textAreas = Array.from(document.getElementsByTagName('textarea'));

    textAreas.forEach((el) => {
      el.oninput = () => {
        el.style.height = 'auto';
        el.style.height = el.scrollHeight - 32 + 'px';
      }
    })
  }, [])

  const handleSendForm = (data) => {
    if (pageType === "contact-us") {
      data.type = questionType;
    }
    if (data.file) {
      data.file = base64DropFile;
    }

    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    axios.post("/sendmail.php", formData)
      .then((response) => {
        if (pageType === "contact-us" && formType === "business-intelligence") {
          navigate('/services/data-analytics/contact-us/confirmation');
        } else if (pageType === "contact-us" && formType === "service-now") {
          navigate('/services/servicenow/contact-us/confirmation');
        } else if (pageType === "contact-us" && !formType) {
          navigate('/contact-us/confirmation');
        } else if (pageType === "demo-request") {
          navigate('/services/data-analytics/demo-request/confirmation');
        } else if (pageType === "register-for-training") {
          navigate('/services/servicenow-training/register-for-training/confirmation');
        } else if (pageType === "apply-now") {
          navigate('/careers/contact-us/confirmation');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const acceptFileTypes = {
    'application/pdf': ['.pdf'],
  };

  const handleDrop = async (acceptedFile, fileRejections) => {
    setIsFormatFileError(false);
    setIsSizeFileError(false);

    if (fileRejections.length === 0) {
      const base64 = await convertBase64(acceptedFile[0]);
      setDropFile(acceptedFile[0]);
      setBase64DropFile(base64);
      setIsDroppedFile(true);
    } else {
      fileRejections[0].errors.forEach((error) => {
        if (error.code === 'file-invalid-type') {
          setIsFormatFileError(true);
        }
        if (error.code === 'file-too-large') {
          setIsSizeFileError(true);
        }
      })
    }
  }

  const handleDeleteDroppedFile = () => {
    setDropFile('');
    setBase64DropFile('');
    setIsDroppedFile(false);
  }

  return (
    <form onSubmit={handleSubmit(handleSendForm)} className={`contact-form ${theme}`} autoComplete="off">
      {pageType === "contact-us" && (
        <DropdownButton
          className='dropdown-button'
          onSelect={handleChangeType}
          title={questionType}
        >
          {messages.questionTypeDropdown.map((item, index) => (
            <Dropdown.Item
              eventKey={item}
              key={index}
              active={questionType === item}
            >
              {item}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
      <div className='contact-form__block'>
        <div className='contact-form__input'>
          <input
            className={errors.firstName ? 'danger' : ''}
            type="text"
            placeholder={messages.firstNamePlaceholder}
            {...register("firstName", { required: true })}
          />
          {errors.firstName?.type === 'required' && (
            <span className="input-error">{messages.inputRequiredError}</span>
          )}
        </div>

        <div className='contact-form__input'>
          <input
            className={errors.lastName ? 'danger' : ''}
            type="text"
            placeholder={messages.lastNamePlaceholder}
            {...register("lastName", { required: true })}
          />
          {errors.lastName?.type === 'required' && (
            <span className="input-error">{messages.inputRequiredError}</span>
          )}
        </div>
      </div>

      <div className='contact-form__input'>
        <input
          className={errors.email ? 'danger' : ''}
          type="text"
          placeholder={messages.emailPlaceholder}
          {...register("email", { required: true, pattern: EMAIL_REGEXP })}
        />
        {errors.email?.type === 'required' && (
          <span className="input-error">{messages.inputRequiredError}</span>
        )}
        {errors.email?.type === 'pattern' && (
          <span className="input-error">{messages.inputEmailError}</span>
        )}
      </div>

      <div className='contact-form__input'>
        <input
          className={errors.phone ? 'danger' : ''}
          type="text"
          placeholder={messages.phonePlaceholder}
          {...register("phone", { required: true, pattern: PHONE_REGEXP })}
        />
        {errors.phone?.type === 'required' && (
          <span className="input-error">{messages.inputRequiredError}</span>
        )}
        {errors.phone?.type === 'pattern' && (
          <span className="input-error">{messages.inputPhoneError}</span>
        )}
      </div>


      <div className='contact-form__input'>
        {pageType === "apply-now" ? (
          <input
            type="text"
            placeholder={messages.linkedInPlaceholder}
            {...register("linkedIn")}
          />
        ) : (
          <input
            type="text"
            placeholder={messages.companyPlaceholder}
            {...register("company")}
          />
        )}
      </div>

      <div className='contact-form__input'>
        <input
          type="text"
          placeholder={messages.countryPlaceholder}
          {...register("country")}
        />
      </div>

      {pageType === "contact-us" && (
        <div className='contact-form__input'>
          <textarea
            rows="1"
            className={errors.question ? 'danger' : ''}
            placeholder={messages.questionPlaceholder}
            {...register("question", { required: true })}
          />
          {errors.question?.type === 'required' && (
            <span className="input-error">{messages.inputRequiredError}</span>
          )}
        </div>
      )}

      {pageType === "demo-request" && (
        <div className='contact-form__input'>
          <textarea
            rows="1"
            placeholder={messages.commentsPlaceholder}
            {...register("comments")}
          />
        </div>
      )}

      {pageType === "apply-now" && (
       <>
         <div className='contact-form__input'>
           <textarea
             rows="1"
             className={errors.comments ? 'danger' : ''}
             placeholder={messages.cvCommentsPlaceholder}
             {...register("comments", { required: true })}
           />
           {errors.comments?.type === 'required' && (
             <span className="input-error">{messages.inputRequiredError}</span>
           )}
         </div>

         <div className="dropzone-block">
           <div className="dropzone-block__header">
             <p>Upload your CV</p>
             {isDroppedFile && (
               <button type="button" onClick={() => handleDeleteDroppedFile()}>
                <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" ><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>

                {/*  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" width="32px" height="32px">
                   <path d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z"/>
                 </svg> */}
               </button>
             )}
           </div>
           {isDroppedFile ? (
             <div className="dropzone">
               <span className="dropzone__file">File: {dropFile?.name}</span>
             </div>
           ) : (
             <Dropzone onDrop={handleDrop} accept={acceptFileTypes} maxFiles={1} maxSize={10000000}>
               {({getRootProps, getInputProps}) => (
                 <div {...getRootProps()} className="dropzone">
                   <input {...getInputProps()} {...register("file")}/>
                   <img src={uploadImage} alt="Upload"/>
                   <p>Click or drag a file to this area to upload.</p>
                 </div>
               )}
             </Dropzone>
           )}
           {isFormatFileError && (
             <span className="dropzone-block__error">
               File type must be .pdf.
             </span>
           )}
           {isSizeFileError && (
             <span className="dropzone-block__error">
               File is larger than 10 mb.
             </span>
           )}
         </div>
       </>
      )}
      {pageType === "register-for-training" && (
        <>
          <div className='contact-form__input'>
            <textarea
              rows="1"
              placeholder={messages.trainingQuestionPlaceholder1}
              {...register("backgroundQuestion")}
            />
          </div>

          <div className='contact-form__input'>
            <textarea
              rows="1"
              className={errors.causesQuestion ? 'danger' : ''}
              placeholder={messages.trainingQuestionPlaceholder2}
              {...register("causesQuestion", { required: true })}
            />
            {errors.causesQuestion?.type === 'required' && (
              <span className="input-error">{messages.inputRequiredError}</span>
            )}
          </div>

          <div className='contact-form__input'>
            <textarea
              rows="1"
              placeholder={messages.trainingQuestionPlaceholder3}
              {...register("anotherQuestion")}
            />
          </div>
        </>
      )}
      <div className='contact-form__input'>
        <div className='checkbox-block'>
          <input
            type="checkbox"
            id="terms-checkbox"
            {...register("terms", { required: true })}
          />
          <label htmlFor="terms-checkbox" className={errors.terms ? 'danger' : ''}>
          <span>{messages.checkbox}{' '}
            <NavLink to="/policy" target="_blank">{messages.checkboxLink}</NavLink>
            {messages.checkbox2}&nbsp;*
          </span>
          </label>
        </div>
        {errors.terms?.type === 'required' && (
          <span className="input-error">{messages.inputRequiredError}</span>
        )}
      </div>

      <p>{messages.info}</p>
      <button className="nav-button" type="submit">
        {pageType === "contact-us" && messages.contactButton}
        {pageType === "demo-request" && messages.requestButton}
        {pageType === "register-for-training" && messages.registrationButton}
        {pageType === "apply-now" && messages.sendApplicationButton}
      </button>
    </form>
  )
}

export {ContactForm}
