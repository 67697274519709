import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";

const Breadcrumbs = ({currentProject}) => {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const [pathArray, setPathArray] = useState();

  const locations = [
    {url: "/services", name: t('servicesPage.title'), prefix: ""},
    {url: "/servicenow", name: t('serviceNowPage.main.title'), prefix: "/services"},
    {url: "/data-analytics", name: t('businessIntelligencePage.main.title'), prefix: "/services"},
    {url: "/servicenow-training", name: t('servicesNowTrainingPage.main.title'), prefix: "/services"}
  ];

  const getBreadcrumbHref = (path) => {
    const prefix = locations.find((l) => l.url === `/${path}`)?.prefix ?? "";
    return `${prefix}/${path}`;
  }

  const getBreadcrumbName = (path) => {
    return locations.find((l) => l.url === `/${path}`)?.name ?? "";
  }

  const getPathArray = (pathname) => {
    return pathname.replace(/\/projects\/.*/, "").split("/").filter(Boolean);
  }

  useEffect(() => {
    setPathArray(getPathArray(pathname));
  }, [pathname]);

  return (
    <div className='breadcrumbs'>
      <ul className="breadcrumbs-block">
        {pathArray?.map((path, index) => {
          if (!currentProject && index + 1 === pathArray?.length) {
            return (
              <li key={index}>{getBreadcrumbName(path)}</li>
            )
          } else {
            return (
              <li key={index}>
                <NavLink to={getBreadcrumbHref(path)}>{getBreadcrumbName(path)}</NavLink>
              </li>
            )
          }
        })}

        {currentProject && (
          <li>{currentProject?.main.title}</li>
        )}
      </ul>
    </div>
  )
}

export {Breadcrumbs}
