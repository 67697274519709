import Calendar from "react-calendar";

const CustomCalendar = ({minDate, maxDate, trainingDates}) => {
  return (
    <Calendar
      locale="en-EN"
      nextLabel=""
      prevLabel=""
      showNeighboringMonth={false}
      minDate={minDate}
      maxDate={maxDate}
      value={minDate}
      minDetail="month"
      formatShortWeekday={(locale, date) => date.toString().slice(0, 2)}
      tileClassName={({date}) => {
        return trainingDates
          .includes(date.toLocaleString("ru").split(',')[0]
            .split('.')
            .reverse().join('/'))
          ? 'highlight' : '';
      }}
    />
  )
}

export {CustomCalendar}
