const CustomParagraph = ({title}) => {
  return (
    <p className='custom-paragraph'>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M14.025 18.2816L12.95 17.2316L17.15 13.0316H4V11.5316H17.15L12.925 7.30659L14 6.25659L20.025 12.2816L14.025 18.2816Z" fill="#48A9A6"/>
      </svg>
      {title}
    </p>
  )
}

export {CustomParagraph}
