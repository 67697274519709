import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import {getDefaultLanguage} from "./common/scripts/getDefaultLanguage";

import commonEn from './common/messages/en/common.json';
import headerEn from './common/messages/en/header.json';
import footerEn from './common/messages/en/footer.json';
import reviewsEn from './common/messages/en/reviews.json';
import contactFormEn from './common/messages/en/contact-form.json';
import chipsEn from './common/messages/en/chips.json';
import homePageEn from './common/messages/en/home-page.json';
import aboutPageEn from './common/messages/en/about-page.json';
import servicesPageEn from './common/messages/en/services-page.json';
import contactsPageEn from './common/messages/en/contacts-page.json';
import careersPageEn from './common/messages/en/careers-page.json';
import servicesNowPageEn from './common/messages/en/service-now-page.json';
import businessIntelligencePageEn from './common/messages/en/business-intelligence-page.json';
import serviceNowTrainingPageEn from './common/messages/en/service-now-training-page.json';
import serviceNowProjectsPageEn from './common/messages/en/service-now-projects.json';
import businessIntelligenceProjectsPageEn from './common/messages/en/business-intelligence-projects.json';
import confirmationPageEn from './common/messages/en/confirmation-page.json';
import pagesWithFormEn from './common/messages/en/pages-with-form.json';
import careersOpportunityPageEn from './common/messages/en/careers-opportunity-page.json';
import privacyPolicyPageEn from './common/messages/en/privacy-policy-page.json';
import termsOfUsePageEn from './common/messages/en/terms-of-use-page.json';
import cookiesModalEn from './common/messages/en/cookies-modal.json';

import commonDe from './common/messages/de/common.json';
import headerDe from './common/messages/de/header.json';
import footerDe from './common/messages/de/footer.json';
import reviewsDe from './common/messages/de/reviews.json';
import contactFormDe from './common/messages/de/contact-form.json';
import chipsDe from './common/messages/de/chips.json';
import homePageDe from './common/messages/de/home-page.json';
import aboutPageDe from './common/messages/de/about-page.json';
import servicesPageDe from './common/messages/de/services-page.json';
import contactsPageDe from './common/messages/de/contacts-page.json';
import careersPageDe from './common/messages/de/careers-page.json';
import servicesNowPageDe from './common/messages/de/service-now-page.json';
import businessIntelligencePageDe from './common/messages/de/business-intelligence-page.json';
import serviceNowTrainingPageDe from './common/messages/de/service-now-training-page.json';
import serviceNowProjectsPageDe from './common/messages/de/service-now-projects.json';
import businessIntelligenceProjectsPageDe from './common/messages/de/business-intelligence-projects.json';
import confirmationPageDe from './common/messages/de/confirmation-page.json';
import pagesWithFormDe from './common/messages/de/pages-with-form.json';
import careersOpportunityPageDe from './common/messages/de/careers-opportunity-page.json';
import privacyPolicyPageDe from './common/messages/de/privacy-policy-page.json';
import termsOfUsePageDe from './common/messages/de/terms-of-use-page.json';
import cookiesModalDe from './common/messages/de/cookies-modal.json';

import commonRu from './common/messages/ru/common.json';
import headerRu from './common/messages/ru/header.json';
import footerRu from './common/messages/ru/footer.json';
import reviewsRu from './common/messages/ru/reviews.json';
import contactFormRu from './common/messages/ru/contact-form.json';
import chipsRu from './common/messages/ru/chips.json';
import homePageRu from './common/messages/ru/home-page.json';
import aboutPageRu from './common/messages/ru/about-page.json';
import servicesPageRu from './common/messages/ru/services-page.json';
import contactsPageRu from './common/messages/ru/contacts-page.json';
import careersPageRu from './common/messages/ru/careers-page.json';
import servicesNowPageRu from './common/messages/ru/service-now-page.json';
import businessIntelligencePageRu from './common/messages/ru/business-intelligence-page.json';
import serviceNowTrainingPageRu from './common/messages/ru/service-now-training-page.json';
import serviceNowProjectsPageRu from './common/messages/ru/service-now-projects.json';
import businessIntelligenceProjectsPageRu from './common/messages/ru/business-intelligence-projects.json';
import confirmationPageRu from './common/messages/ru/confirmation-page.json';
import pagesWithFormRu from './common/messages/ru/pages-with-form.json';
import careersOpportunityPageRu from './common/messages/ru/careers-opportunity-page.json';
import privacyPolicyPageRu from './common/messages/ru/privacy-policy-page.json';
import termsOfUsePageRu from './common/messages/ru/terms-of-use-page.json';
import cookiesModalRu from './common/messages/ru/cookies-modal.json';

const en = {
  ...commonEn,
  ...headerEn,
  ...footerEn,
  ...reviewsEn,
  ...contactFormEn,
  ...chipsEn,
  ...homePageEn,
  ...aboutPageEn,
  ...servicesPageEn,
  ...contactsPageEn,
  ...careersPageEn,
  ...servicesNowPageEn,
  ...businessIntelligencePageEn,
  ...serviceNowTrainingPageEn,
  ...serviceNowProjectsPageEn,
  ...businessIntelligenceProjectsPageEn,
  ...confirmationPageEn,
  ...pagesWithFormEn,
  ...careersOpportunityPageEn,
  ...privacyPolicyPageEn,
  ...termsOfUsePageEn,
  ...cookiesModalEn,
};

const de = {
  ...commonDe,
  ...headerDe,
  ...footerDe,
  ...reviewsDe,
  ...contactFormDe,
  ...chipsDe,
  ...homePageDe,
  ...aboutPageDe,
  ...servicesPageDe,
  ...contactsPageDe,
  ...careersPageDe,
  ...servicesNowPageDe,
  ...businessIntelligencePageDe,
  ...serviceNowTrainingPageDe,
  ...serviceNowProjectsPageDe,
  ...businessIntelligenceProjectsPageDe,
  ...confirmationPageDe,
  ...pagesWithFormDe,
  ...careersOpportunityPageDe,
  ...privacyPolicyPageDe,
  ...termsOfUsePageDe,
  ...cookiesModalDe,
};

const ru = {
  ...commonRu,
  ...headerRu,
  ...footerRu,
  ...reviewsRu,
  ...contactFormRu,
  ...chipsRu,
  ...homePageRu,
  ...aboutPageRu,
  ...servicesPageRu,
  ...contactsPageRu,
  ...careersPageRu,
  ...servicesNowPageRu,
  ...businessIntelligencePageRu,
  ...serviceNowTrainingPageRu,
  ...serviceNowProjectsPageRu,
  ...businessIntelligenceProjectsPageRu,
  ...confirmationPageRu,
  ...pagesWithFormRu,
  ...careersOpportunityPageRu,
  ...privacyPolicyPageRu,
  ...termsOfUsePageRu,
  ...cookiesModalRu,
};

i18next.use(initReactI18next).init({
  fallbackLng: getDefaultLanguage(),
  lng: localStorage.getItem("language") ?? getDefaultLanguage(),
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
    ru: {
      translation: ru,
    }
  }
});
