import {useEffect, useContext, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

import {Context} from "../../common/scripts/context";
import {activateScrollPage} from "../../common/scripts/activateScrollPage";
import {useWindowSize} from "../../common/hooks/useWindowSize";
import {PageNav} from "../../components/PageNav";
import {Paragraphs} from "../../components/Paragraphs";
import {Breadcrumbs} from "../../components/Breadcrumbs";

import projectImage1 from "../../common/images/bi-project1.png";
import projectImage2 from "../../common/images/bi-project1.png";

const BusinessIntelligenceProjectPage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('businessIntelligenceProjects.body', { returnObjects: true });
  const defaultMenuMessage = t('businessIntelligenceProjects.menu', { returnObjects: true });

  const {setFooterTheme} = useContext(Context);
  const [currentProject, setCurrentProject] = useState();
  const [activeLink, setActiveLink] = useState();
  const [projects, setProjects] = useState(defaultMessage);
  const [menu, setMenu] = useState(defaultMenuMessage);
  const {height} = useWindowSize();
  const {linkTitle} = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProjects(defaultMessage);
    setMenu(defaultMenuMessage);
  }, [i18n.language, t]);

  useEffect(() => {
    setCurrentProject(projects?.find((el) => el.linkTitle === linkTitle));
  }, [projects, linkTitle]);

  useEffect(() => {
    setFooterTheme('light align-left');
  }, [setFooterTheme]);

  useEffect(() => {
    activateScrollPage(height, activeLink, setActiveLink);
  }, [activeLink, setActiveLink, height]);


  const verticalMenuLinkArray = [
    {href: "#main", title: menu.links[0]},
    {href: "#background", title: menu.links[1]},
    {href: "#challenges", title: menu.links[2]},
    {href: "#solution", title: menu.links[3]},
    {href: "#results", title: menu.links[4]},
    {href: "#info", title: menu.links[5]},
  ];

  const imagesArray = [
    {linkTitle: "document-management-system", imgHref: projectImage1},
    {linkTitle: "222", imgHref: projectImage2},
    {linkTitle: "333", imgHref: projectImage2},
  ];

  if (!currentProject) {
    return (
      <div className="page"/>
    )
  }

  return (
    <div className="project-page page">
      <div className="project-page__blocks">
        <div className="project-page__info-block">
          <section className='project-page__main' id="main">
            <Breadcrumbs currentProject={currentProject}/>

            <div className='project-page__main-info'>
              <h2>{currentProject?.main.title}</h2>
              <p>{currentProject?.main.info}</p>
            </div>
            <img
              src={imagesArray.find((el) => el.linkTitle === linkTitle).imgHref}
              alt="Project screen"
            />
          </section>

          <section className='project-page__background' id="background">
            <h3>{currentProject?.background.title}</h3>
            <p>{currentProject?.background.description}</p>
          </section>

          <section className='project-page__challenges' id="challenges">
            <Paragraphs paragraphsList={currentProject?.challenges} theme="dark"/>
          </section>

          <section className='project-page__solution' id="solution">
            <Paragraphs paragraphsList={currentProject?.solution}/>
          </section>

          <section className='project-page__results' id="results">
            <div className='list-block'>
              <h3>{currentProject?.results.title}</h3>
              <p>{currentProject?.results.description}</p>

              {currentProject?.results.options.map((el, index) => (
                <div className="list-block__item" key={index}>
                  <h3>{el.title}</h3>
                  <p>{el.description}</p>
                </div>
              ))}
            </div>
          </section>

          <section className='project-page__product-info' id="info">
            <div className="project-page__product-info-block">
              <h3>{currentProject?.info.title}</h3>
              {currentProject?.info.options.map((el, index) => (
                <div key={index}>
                  <span>{el.info + " "}</span>
                  <p>{el.description}</p>
                </div>
              ))}
            </div>
          </section>

          <section className='project-page__connect' id="connect">
            <h3>{currentProject?.connect.title}</h3>
            <p>{currentProject?.connect.description}</p>
            <div className='page-nav__button'>
              <NavLink className='nav-button' to='/contacts'>
                {currentProject?.connect.button}
              </NavLink>
            </div>
          </section>
        </div>

        <div className="project-page__menu-block">
          <PageNav
            linkArray={verticalMenuLinkArray}
            activeLink={activeLink}
            linkTitle={menu.button}
            link="/services/data-analytics/demo-request"
          />
        </div>
      </div>
    </div>
  )
}

export {BusinessIntelligenceProjectPage}
