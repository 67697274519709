import {useEffect, useContext, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";

import {Context} from "../../common/scripts/context";
import {useWindowSize} from "../../common/hooks/useWindowSize";
import {activateScrollPage} from "../../common/scripts/activateScrollPage";
import {PageNav} from "../../components/PageNav";
import {Paragraphs} from "../../components/Paragraphs";
import {Breadcrumbs} from "../../components/Breadcrumbs";

import projectImage1 from "../../common/images/sn-project1.png";

const ServiceNowProjectPage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('serviceNowProjects.body', {returnObjects: true});
  const defaultLinksMessage = t('serviceNowProjects.links', {returnObjects: true});

  const {setFooterTheme} = useContext(Context);
  const [currentProject, setCurrentProject] = useState();
  const [activeLink, setActiveLink] = useState();
  const [projects, setProjects] = useState(defaultMessage);
  const [links, setLinks] = useState(defaultLinksMessage);
  const {height} = useWindowSize();
  const {linkTitle} = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProjects(defaultMessage);
    setLinks(defaultLinksMessage);
  }, [i18n.language, t]);

  useEffect(() => {
    setCurrentProject(projects?.find((el) => el.linkTitle === linkTitle));
  }, [projects, linkTitle]);

  useEffect(() => {
    setFooterTheme('light align-left');
  }, [setFooterTheme]);

  useEffect(() => {
    activateScrollPage(height, activeLink, setActiveLink);
  }, [activeLink, setActiveLink, height]);

  const verticalMenuLinkArray = [
    {href: "#main", title: links?.[0]},
    {href: "#benefits", title: links?.[1]},
    {href: "#resources", title: links?.[2]},
  ];

  const imagesArray = [
    {linkTitle: "security-operations", imgHref: projectImage1},
    {linkTitle: "2222", imgHref: projectImage1},
    {linkTitle: "3333", imgHref: projectImage1},
  ];

  if (!currentProject) {
    return (
      <div className="page"/>
    )
  }

  return (
    <div className="project-page page">
      <div className="project-page__blocks">
        <div className="project-page__info-block">
          <section className='project-page__main' id="main">
            <Breadcrumbs currentProject={currentProject}/>

            <h2>{currentProject?.main.title}</h2>
            <p>{currentProject?.main.description}</p>
            <img
              src={imagesArray.find((el) => el.linkTitle === linkTitle)?.imgHref}
              alt="Project screen"/>
          </section>

          <section className='project-page__benefits' id="benefits">
            <Paragraphs paragraphsList={currentProject?.benefits} theme="dark"/>
          </section>

          <section className='project-page__resources' id="resources">
           <div className='list-block'>
             <h3>{currentProject?.resources.title}</h3>
             <p>{currentProject?.resources.description}</p>

             {currentProject?.resources.options.map((el, index) => (
               <div className="list-block__item" key={index}>
                 <h3>{el.title}</h3>
                 <p>{el.description}</p>
               </div>
             ))}
           </div>
          </section>
        </div>

        <div className="project-page__menu-block">
          <PageNav linkArray={verticalMenuLinkArray} activeLink={activeLink}/>
        </div>
      </div>
    </div>
  )
}

export {ServiceNowProjectPage}
