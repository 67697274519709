import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Context} from "../common/scripts/context";
import {InfoBlock} from "../components/InfoBlock";
import {ContactForm} from "../components/ContactForm";

import placeImage from "../common/images/place.svg";
import phoneImage from "../common/images/phone.svg";
import mailImage from "../common/images/mail.svg";

const ContactsPage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('contactsPage', {returnObjects: true});

  const {setFooterTheme} = useContext(Context);
  const [messages, setMessages] = useState(defaultMessage);

  const mainInfoParams = [
    {imgHref: placeImage},
    {imgHref: phoneImage},
    {imgHref: mailImage},
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFooterTheme('dark');
  }, [setFooterTheme]);

  useEffect(() => {
    setMessages(defaultMessage);
  }, [i18n.language, t]);

  const getTypeInfoBlock = (index) => {
    if (index === 1) {
      return "phone";
    } else if (index === 2) {
      return "email";
    }
    return null;
  }

  return (
    <div className='contacts-page page'>
      <section className='contacts-page__main'>
        <div className="section-wrapper">
          <h2>{t('contactsPage.main.title')}</h2>
          <div className='contacts-page__main-info'>
            {messages.main.options.map((el, index) => (
              <InfoBlock
                key={index}
                title={el.title}
                info={el.info}
                imgHref={mainInfoParams[index].imgHref}
                theme='light'
                type={getTypeInfoBlock(index)}
              />
            ))}
          </div>
        </div>
      </section>

      <section className='contacts-page__form'>
        <div className="section-wrapper">
          <h2>{t('contactsPage.form.title')}</h2>
          <p>{t('contactsPage.form.description')}</p>
          <ContactForm theme='dark' pageType='contact-us'/>
        </div>
      </section>
    </div>
  )
}

export {ContactsPage}
