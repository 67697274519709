import {NavLink} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Context} from "../common/scripts/context";
import {ServiceBlock} from "../components/ServiceBlock";
import {InfoBlock} from "../components/InfoBlock";

import teamImage from '../common/images/about_us.jpg';
import codeBracesLightImage from '../common/images/code-braces-light.svg';
import schoolOutlineLightImage from '../common/images/school-outline-light.svg';
import chartLineLightImage from '../common/images/chart-line-light.svg';
// import ourWorkImage from '../common/images/our-work.png';
import firstExpertImage from '../common/images/expert-servicenow.png';
import secondExpertImage from '../common/images/expert-kafka.png';
import thirdExpertImage from '../common/images/expert-mongo.png';

import codeBracesImage from "../common/images/rocket_launch.svg";
import chartLineImage from "../common/images/chart-line.svg";
import schoolOutlineImage from "../common/images/library_add.svg";


const AboutPage = () => {
  const {t, i18n} = useTranslation();
  const defaultMessage = t('aboutPage', {returnObjects: true});

  const {setFooterTheme} = useContext(Context);
  const [messages, setMessages] = useState(defaultMessage);

  const mainInfoParams = [
    {imgHref: codeBracesImage},
    {imgHref: chartLineImage},
    {imgHref: schoolOutlineImage},
  ];

  const servicesParams = [
    {
      imgHref: codeBracesLightImage,
      link: "/services/servicenow",
      title: t('aboutPage.services.blockTitle1')
    },
    {
      imgHref: chartLineLightImage,
      link: "/services/data-analytics",
      title: t('aboutPage.services.blockTitle2')
    },
    {
      imgHref: schoolOutlineLightImage,
      link: "/services/servicenow-training",
      title: t('aboutPage.services.blockTitle3')
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFooterTheme('dark');
  }, [setFooterTheme]);

  useEffect(() => {
    setMessages(defaultMessage);
  }, [i18n.language, t]);

  return (
    <div className='about-page page'>
      <section className='about-page__main'>
        <div className="section-wrapper">
          <h2>{t('aboutPage.main.title')}</h2>
          <div className='about-page__main-info'>
            <div className='about-page__main-img'>
              <img src={teamImage} alt="Our team"/>
            </div>
            <p>{t('aboutPage.main.description')}</p>
          </div>

          <div className='about-page__main-block'>
            {messages.main.options.map((el, index) => (
              <InfoBlock
                key={index}
                title={el.title}
                info={el.info}
                imgHref={mainInfoParams[index].imgHref}
                theme='light'
              />
            ))}
          </div>
        </div>
      </section>

      <section className='about-page__services'>
        <div className="section-wrapper">
          <h2>{t('aboutPage.services.title')}</h2>
          <div className='about-page__services-block'>
            {servicesParams.map((el, index) => (
              <ServiceBlock link={el.link} imgHref={el.imgHref} title={el.title} key={index}/>
            ))}
          </div>
          </div>
      </section>

       {/*<section className='about-page__work'>
         <div className="section-wrapper">
           <h2>{t('aboutPage.work.title')}</h2>
           <p>{t('aboutPage.work.description')}</p>
           <img src={ourWorkImage} alt="How we work"/>
         </div>
      </section>

      <section className='about-page__achievements'>
        <div className="section-wrapper">
          <h2>{t('aboutPage.achievements.title')}</h2>
          <div className='about-page__achievements-block'>
            {messages.achievements.options.map((el, index) => (
              <div key={index}>
                <h3>{el.title}</h3>
                <p>{el.info}</p>
              </div>
            ))}
          </div>
        </div>
      </section>*/}

      <section className='about-page__experts'>
        <div className="section-wrapper">
          <h2>{t('aboutPage.experts.title')}</h2>
          <p>{t('aboutPage.experts.description')}</p>
          <div className='about-page__experts-block'>
            <img src={firstExpertImage} width="25%" height="25%" alt="ServiceNow"/>
            <img src={secondExpertImage} width="20%" height="20%" alt="Kafka"/>
            <img src={thirdExpertImage} width="20%" height="20%" alt="MongoDB"/>

          </div>
          <div className='about-page__experts-button'>
            <NavLink className='nav-button' to='/contacts'>{t('aboutPage.experts.button')}</NavLink>
          </div>
        </div>
      </section>
    </div>
  )
}

export {AboutPage}
