import {Accordion} from "react-bootstrap";

const CustomAccordion = ({messages}) => {
  const header = (questionNumber, headerMessages) => {
    return (
      <Accordion.Header>
        <span className="accordion-header__count">{questionNumber}.</span>
        <div className="accordion-header__block-title">
          <p className="accordion-header__title">{headerMessages.title}</p>
          {headerMessages.duration && (
            <span className="accordion-header__duration">({headerMessages.duration})</span>
          )}
        </div>
      </Accordion.Header>
    )
  }

  return (
    <Accordion>
      <Accordion.Item eventKey='1'>
        {header(1, messages.question1)}
        <Accordion.Body>
          <p>{messages.question1.description}</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='2'>
        {header(2, messages.question2)}
        <Accordion.Body>
          <p>{messages.question2.description}</p>
          <ul>
            {messages.question2.options.map((el, index) => (
              <li key={index}>{el}</li>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='3'>
        {header(3, messages.question3)}
        <Accordion.Body>
          <p>{messages.question3.description}</p>

          <div>
            <h5>{messages.question3.block1.title}</h5>
            <p>{messages.question3.block1.description}</p>
          </div>

          <div>
            <h5>{messages.question3.block2.title}</h5>
            <p>{messages.question3.block2.description}</p>
            <ul>
              {messages.question3.block2.options.map((el, index) => (
                <li key={index}>{el}</li>
              ))}
            </ul>
          </div>

          <div>
            <h5>{messages.question3.block3.title}</h5>
            <p>{messages.question3.block3.description}</p>
            <ul>
              {messages.question3.block3.options.map((el, index) => (
                <li key={index}>{el}</li>
              ))}
            </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='4'>
        {header(4, messages.question4)}
        <Accordion.Body>
          <p>{messages.question4.description}</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='5'>
        {header(5, messages.question5)}
        <Accordion.Body>
          <p>{messages.question5.description}</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='6'>
        {header(6, messages.question6)}
        <Accordion.Body>
          <p>{messages.question6.description}</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='7'>
        {header(7, messages.question7)}
        <Accordion.Body>
          <p>{messages.question7.description}</p>
          <ul>
            {messages.question7.options.map((el, index) => (
              <li key={index}>{el}</li>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='8'>
        {header(8, messages.question8)}
        <Accordion.Body>
          <p>{messages.question8.description}</p>
          <ul>
            {messages.question8.options.map((el, index) => (
              <li key={index}>{el}</li>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export {CustomAccordion}
